<template>
  <div class="home">
    <Header @handlePage="toAboutUs"/>
    <div class="main">
      <video
        ref="video"
        webkit-playsinline="true"
        poster="@/assets/imgs/video.png"
        playsinline="true"
        preload='auto'
        controls
        autoplay
        muted
      >
        <source src="https://ipfs.io/ipfs/QmXaLEUwD6tEYNtgUNwFoYqzExsg9sAcNYxnBsj4YtV37n?filename=My%20World%20Meta%20Utopia.mp4" type="video/mp4">
      </video>
      <div class="part1">
        <div class="join">
          <p>JOIN YOUR CITY BUILD YOUR CITY</p>
          <p><span class="blue">54 CITIES</span> <span class="yellow">IN UTOPIA</span></p>
        </div>
        <div class="logo"><img src="@/assets/imgs/logo_text.png" alt=""></div>
        <div class="sandbox">in the Sandbox Metaverse</div>
        <div class="img"><img src="@/assets/imgs/sandbox.png" alt=""></div>
        <router-link to="/cities">
          <div class="btn">Read More Cities In Utopia</div>
        </router-link>
      </div>
      <div class="part2">
        <van-swipe class="my-swipe" :autoplay="3000">
          <van-swipe-item><img src="@/assets/imgs/01-META UTOPIA.jpg" alt=""></van-swipe-item>
          <van-swipe-item><img src="@/assets/imgs/02-META DUBAI.jpg" alt=""></van-swipe-item>
          <van-swipe-item><img src="@/assets/imgs/03-RODNEYVERSE.jpg" alt=""></van-swipe-item>
          <van-swipe-item><img src="@/assets/imgs/04-META RAMS CITY.jpg" alt=""></van-swipe-item>
          <van-swipe-item><img src="@/assets/imgs/05-META IMPERIUM ROME.jpg" alt=""></van-swipe-item>
          <van-swipe-item><img src="@/assets/imgs/06-META LUXOR.jpg" alt=""></van-swipe-item>
          <van-swipe-item><img src="@/assets/imgs/07-META NAPLES.jpg" alt=""></van-swipe-item>
          <van-swipe-item><img src="@/assets/imgs/08-META DOLITRUS.jpg" alt=""></van-swipe-item>
          <van-swipe-item><img src="@/assets/imgs/09-META LONDONVERSE.jpg" alt=""></van-swipe-item>
          <van-swipe-item><img src="@/assets/imgs/10-META SWISS.jpg" alt=""></van-swipe-item>
          <van-swipe-item><img src="@/assets/imgs/11-META BEIJING.jpg" alt=""></van-swipe-item>
          <van-swipe-item><img src="@/assets/imgs/12-META SINGAPORE.jpg" alt=""></van-swipe-item>
          <van-swipe-item><img src="@/assets/imgs/13-META BERLIN.jpg" alt=""></van-swipe-item>
          <van-swipe-item><img src="@/assets/imgs/14-BEAUTEEVERSE.jpg" alt=""></van-swipe-item>
          <van-swipe-item><img src="@/assets/imgs/15-META KUALA LUMPUR.jpg" alt=""></van-swipe-item>
          <van-swipe-item><img src="@/assets/imgs/16-META MARSVERSE.jpg" alt=""></van-swipe-item>
          <van-swipe-item><img src="@/assets/imgs/17-META NEW YORK.jpg" alt=""></van-swipe-item>
          <van-swipe-item><img src="@/assets/imgs/18-META PARIS.jpg" alt=""></van-swipe-item>
          <template #indicator>
            <div class="custom-indicator"></div>
          </template>
        </van-swipe>
        <div ref="about" class="aboutUs"></div>
      </div>
      <div class="part3">
        <div class="text">
          <p class="about">ABOUT US</p>
          <p>ORIGIN OF</p>
          <p>META-UTOPIA</p>
          <div class="small">
            <p>The target of the Meta-Utopia is to</p>
            <p>realize a sustainable virtual</p>
            <p>ecosystem and create a peaceful</p>
            <p>environment for all its citizens.</p>
          </div>
        </div>
        <div class="img"><img src="@/assets/imgs/about_img.png" alt=""></div>
        <router-link to="/join">
          <div class="btn" style="margin-top: 20px;">Create your city</div>
        </router-link>
      </div>
      <div class="part4">
        <div class="downBtn">
          <a href="https://bafybeiecn6dgfnz5yyfv6vpdcuqbaazzr6qiofjdkgmngol5oj3qoi4ynq.ipfs.nftstorage.link/Final%20Meta-Utopia%20V1.2.pdf" class="text">
            <p>download</p>
            <p>white paper</p>
          </a>
          <img src="@/assets/imgs/download.png" alt="">
        </div>
        <div class="downBtn" style="margin-bottom: 30px;">
          <a href="https://ipfs.io/ipfs/QmRWEqpEciMgAd3rxKpT3rPE6KY2YLModCaord4QnyPdNs?filename=Certik%20final%20report.pdf" class="text">
            <p>DOWNLOAD</p>
            <p>AUDIT REPORT</p>
          </a>
          <img src="@/assets/imgs/download.png" alt="">
        </div>
        <router-link to="/join">
          <div class="btn">Join Us Now</div>
        </router-link>
        <div class="day">Don’t Have a Good Day, Have a Great Day!</div>
      </div>
      <div class="part5">
        <div class="img"><img src="@/assets/imgs/day_img.png" alt=""></div>
        <div class="text">
          <div class="yellow">
            <p>DON'T</p>
            <p>HAVE A GOOD DAY</p>
            <p>HAVE A GREAT DAY!</p>
          </div>
          <div class="blue">WELCOME TO META-UTOPIA</div>
          <div class="small">
            <p>Meta-Utopia is a MetaFi project driven</p>
            <p>by community consensus to build an ideal world,</p>
            <p>an Utopia in the Metaverse.</p>
          </div>
        </div>
        <router-link to="/join">
          <div class="btn">Join Us Now</div>
        </router-link>
      </div>
      <div class="part6">
        <div class="text">
          <div class="red">META UTOPIA ANGELS</div>
          <div class="black">
            <p>COMMUNITY</p>
            <p>BUILDERS FROM</p>
            <p>META UTOPIA</p>
          </div>
        </div>
        <div class="img">
          <img src="@/assets/imgs/angel_img.png" alt="">
          <div class="icons">
            <div class="item">
              <img src="@/assets/imgs/active.png" alt="">
              <p>Active</p>
            </div>
            <div class="item">
              <img src="@/assets/imgs/passionate.png" alt="">
              <p>Passionate</p>
            </div>
            <div class="item">
              <img src="@/assets/imgs/volunteers.png" alt="">
              <p>Volunteers</p>
            </div>
          </div>
        </div>
        <div class="angel">Mission of an Angel</div>
        <router-link to="/join">
          <div class="btn2">Meta Utopia Wants You</div>
        </router-link>
      </div>
      <div class="part7">
        <div class="building">
          <p>BUILDING</p>
          <p>THE METAVERSE</p>
          <p>TOGETHER</p>
        </div>
        <div class="thank">Thank you for the support</div>
        <div class="logos"><img src="@/assets/imgs/logos.png" alt=""></div>
        <div class="roadmap">
          <p class="meta">META-UTOPIA</p>
          <p class="our">OUR ROADMAP</p>
          <router-link to="/roadmap">
            <div class="btn learn">Learn More</div>
          </router-link>
          <div class="img"><img src="@/assets/imgs/roadmap.png" alt=""></div>
        </div>
        <div class="season">
          <div class="item">
            <p class="title">Season 1:</p>
            <p class="content">The Era of Cultivation</p>
          </div>
          <div class="item">
            <p class="title">Season 2:</p>
            <p class="content">The Era of Building and Development</p>
          </div>
          <div class="item">
            <p class="title">Season 3:</p>
            <p class="content">The Era of the Future: MetaFI</p>
          </div>
        </div>
      </div>
      <Follow/>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/Header'
import Follow from "@/components/Follow";
import Footer from "@/components/Footer";

export default {
  name: 'Home',
  components: { Header, Follow, Footer },
  mounted() {
    document.addEventListener('WeixinJSBridgeReady',() => {
      this.$refs.video.play();
    },false);

    if(this.$route.query.tag) {
      this.toAboutUs();
    }
  },
  methods: {
    toAboutUs() {
      this.$refs.about.scrollIntoView(true)
    }
  }
}
</script>

<style lang="less" scoped>
.main{
  margin-top: 49px;
  position: relative;
  color: #fff;
  video{
    width: 100%;
    object-fit: fill;
    display: block;
  }
  .part1{
    background-color: #12191c;
    padding-bottom: 40px;
    .join{
      font-family: Montserrat-Bold, Verdana;
      font-size: 16px;
      font-weight: bold;
      line-height: 23px;
      padding: 30px 0;
      .blue{
        color: #0BF0DB;
      }
      .yellow{
        color: #FFDD00;
      }
    }
    .logo{
      width: 147px;
      margin: 0 auto;
    }
    .sandbox{
      font-family: Montserrat-Bold, Verdana;
      font-size: 16px;
      font-weight: bold;
      line-height: 23px;
      text-transform: uppercase;
    }
    .img{
      width: 333px;
      margin: 0 auto;
    }
  }
  .part2{
    position: relative;
    .aboutUs{
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 0;
      height: 49px;
    }
  }
  .part3{
    padding-bottom: 45px;
    background: url("~@/assets/imgs/about_bg.png");
    background-size: 100% 100%;
    .text{
      font-family: Montserrat-ExtraBold, Verdana;
      font-size: 28px;
      font-weight: bold;
      text-align: left;
      padding: 30px 0 0 40px;
      line-height: 40px;
      .about{
        font-family: Montserrat, Verdana;
        font-size: 16px;
        line-height: 30px;
        background-image: -webkit-linear-gradient(top,#0bedad,#0ba191);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      .small{
        font-size: 14px;
        line-height: 25px;
        font-family: Montserrat-Medium, Verdana;
        font-weight: normal;
        margin: 15px 0;
      }
    }
  }
  .part4{
    background-color: #12191c;
    padding: 50px 0;
    .downBtn{
      margin: 0 auto 38px auto;
      width: 287px;
      height: 65px;
      line-height: 65px;
      border-radius: 20px;
      background: linear-gradient(180deg, #0B1A20 0%, #131D23 100%);
      box-shadow: 0 3px 15px 8px rgba(0, 0, 0, 0.3),inset 0 0 20px 3px rgba(0, 255, 183, 0.36);
      border: 3px solid #0cae9d;
      position: relative;
      .text{
        height: 100%;
        display: flex;
        flex-direction: column;
        font-family: Montserrat-Bold, Verdana;
        font-size: 14px;
        font-weight: bold;
        line-height: 20px;
        text-transform: uppercase;
        text-align: left;
        margin-left: 122px;
        justify-content: center;
        color: #fff;
      }
      img{
        width: 92px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 13px;
      }
    }
    .day{
      font-family: Montserrat-Regular, Verdana;
      font-size: 12px;
      line-height: 20px;
      margin-top: 15px;
    }
  }
  .part5{
    padding: 10px 0 40px 0;
    background: linear-gradient(180deg, #111f4f 0%, #30055e 100%);
    .img{
      width: 303px;
      margin: 0 auto;
    }
    .text{
      margin: -36px 30px 30px 0;
      text-align: right;
      .yellow{
        font-family: Montserrat-ExtraBold, Verdana;
        font-size: 28px;
        font-weight: bold;
        line-height: 40px;
        color: #FFDD00;
      }
      .blue{
        font-family: Montserrat-Bold, Verdana;
        font-size: 16px;
        font-weight: bold;
        line-height: 23px;
        margin: 8px 0 14px 0;
        background-image: -webkit-linear-gradient(top,#0bedad,#0ba191);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      .small{
        font-family: Montserrat-Regular, Verdana;
        font-size: 12px;
        line-height: 20px;
      }
    }
  }
  .part6{
    padding: 40px 0;
    background: linear-gradient(180deg, #ecfbf9 0%, #4cdbcd 100%);
    .text{
      margin-left: 34px;
      text-align: left;
      .red{
        font-family: Montserrat-Bold, Verdana;
        font-size: 16px;
        font-weight: bold;
        line-height: 23px;
        color: #F05434;
      }
      .black{
        font-family: Montserrat-ExtraBold, Verdana;
        font-size: 28px;
        font-weight: bold;
        line-height: 40px;
        color: #020202;
        margin-top: 12px;
      }
    }
    .img{
      height: 198px;
      position: relative;
      margin-top: 5px;
      >img{
        width: 71%;
        position: absolute;
        right: 0;
      }
      .icons{
        position: absolute;
        left: 32px;
        bottom: 5px;
        .item{
          display: flex;
          align-items: center;
          margin-bottom: 17px;
          img{
            width: 34px;
          }
          p{
            font-family: Montserrat-Regular, Verdana;
            font-size: 12px;
            color: #000;
            margin-left: 10px;
          }
        }
        .item:last-child{
          margin-bottom: 0;
        }
      }
    }
    .angel{
      font-family: Montserrat-Medium, Verdana;
      font-size: 14px;
      line-height: 25px;
      color: #000;
      margin: 26px 0 10px 0;
    }
  }
  .part7{
    background-color: #12191c;
    padding-top: 40px;
    .building{
      font-family: Montserrat-ExtraBold, Verdana;
      font-weight: bold;
      font-size: 28px;
      line-height: 40px;
    }
    .thank{
      font-family: Montserrat-Medium, Verdana;
      font-size: 14px;
      line-height: 25px;
      margin: 7px 0 20px 0;
      background-image: -webkit-linear-gradient(top,#0bedad,#0ba191);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .logos{
      width: 310px;
      margin: 0 auto 84px auto;
    }
    .roadmap{
      border: 3px solid #0bad9c;
      padding: 15px 17px 23px 0;
      margin: 0 30px 12px 30px;
      border-radius: 20px;
      background: linear-gradient(180deg, #0B1A20 0%, #131D23 100%);
      box-shadow: 0 3px 15px 8px rgba(0, 0, 0, 0.3),inset 0 0 20px 3px rgba(0, 255, 183, 0.36);
      display: flex;
      flex-direction: column;
      text-align: right;
      align-items: flex-end;
      position: relative;
      .meta{
        font-family: Montserrat-Bold, Verdana;
        font-size: 16px;
        font-weight: bold;
        line-height: 23px;
        color: #fff;
      }
      .our{
        font-family: Montserrat-ExtraBold, Verdana;
        font-size: 28px;
        font-weight: bold;
        line-height: 40px;
        color: #CD1558;
        margin: 3px 0 7px 0;
      }
      .learn{
        width: 170px;
        margin: 0;
        text-align: center;
      }
      .img{
        width: 117px;
        position: absolute;
        left: -27px;
        top: -50px;
      }
    }
    .season{
      text-align: right;
      margin-right: 40px;
      padding-bottom: 34px;
      .item{
        margin-bottom: 15px;
        font-family: Montserrat-Bold, Verdana;
        font-size: 14px;
        font-weight: bold;
        line-height: 20px;
        .content{
          color: #05A990;
        }
      }
      .item:last-child{
        margin-bottom: 0;
      }
    }
  }
}
</style>
